<template>
  <div class="setting-page padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">{{ $t('b_list_approval.title_approval_of_emissions') }}</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html="$t('b_list_approval.tooltip_approval_use')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="approvalGrid"
        :grid-columns="approvalColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="labelEmpty"
        :isResizeCustome="false"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        class="table-list-approval"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>
  
<script>
import DataTable from "@/components/category/data-table";
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import LogHistoryPopup from "@/components/dialogs/history-popup.vue";
import { getProductsListWorkflowApi,getProductListLogHistory,getProductListCommentHistory } from "@/api/product";
import { getUserInfo } from "@/api/auth";
import { ROUTES } from "@/router/constants";
import { STATUS_FIELD } from "@/constants/status";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE } from "@/constants/registerData";
import { getColorStatusById, getStatusNameById, getColorTextStatusById } from "@/utils/status";
import { getCategoryName, getDataLayer, getScopeName, getPeriodFromMonthYear } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";
import { mapActions, mapState } from "vuex";
import * as wjcCore from "@mescius/wijmo";
import { CollectionView } from "@mescius/wijmo";
import { UndoStack } from "@/concerns/utils/undo-stack";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import * as wjGrid from "@mescius/wijmo.grid";
import throttle from "lodash/throttle";
import { cancelSubmittedData } from '@/api/newRegisterData';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { getStartMonth } from "@/api/duration";
import moment from "moment";
import { getWidthOfDetailButton, getMinWidthOrganization } from '@/utils/multiLanguage'
import { getWidthByText } from '@/utils/getWidthByText'
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
export default {
  name: 'ApprovalPage',
  components: {
    DataTable,
    CommentLogPopup,
    LogHistoryPopup,
  },
  data() {
    return {
      approvalGrid: null,
      flexGrid: null,
      approvalData: [],
      approvalColumn: [],
      dialogChangeStatus: false,
      dialogComment: false,
      listStatusApproval: [],
      showTooltip: false,
      breadCrumb: [
        {
          text: this.$t('b_list_approval.hyperlink_home'),
          disabled: false,
          href: ROUTES.PRODUCTS
        },
        {
          text: this.$t('b_list_approval.label_approval_of_emissions'),
          disabled: true,
          href: `${ROUTES.PRODUCTS}/${ROUTES.PRODUCTS_LIST_APPROVAL}`
        }
      ],
      commentContents: [],
      filterColumns: [],
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      userName: '',
      listLayer: [],
      isEmptySource: true,
      labelEmpty: this.$t('b_list_approval.label_empty'),
      startMonth: null,
    };
  },
  async mounted() {
    if (this.$route.query?.is_allow_cancel) {
      await this.handleApprovalCancleSubmit();
    }
    try {
      this.actionUpdateIsLoadingTable(true)
      await this.checkUserInfo();
      await getStartMonth(this.$store.state.userData.contractor).then((res) => {
        this.startMonth = res.data.start_month;
      });
      await this.handleGetDataApproval();
      this.actionUpdateIsLoadingTable(false)
    } catch (error) {
      this.actionUpdateIsLoadingTable(false)
    }
    this.updateBreadCrumb(this.breadCrumb);
    this.listStatusApproval = [
      {
        name: '承認',
        description: '申請を承認します。',
      },
      {
        name: '申請差戻',
        description: '申請を却下し、申請者に差し戻します。',
      },
    ];
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),
    ...mapActions('newRegisterData', ['updateIsApproved', 'updatePatternId']),
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.userName = res.name;
        })
        .catch(() => {});
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    initGrid(grid) {
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false,
      );
      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];
        const listColumnNotDisable = ['unique_id', 'log_history'];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
      });
      grid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    async handleGetDataApproval() {
      const payload = {
        action: 'listApproval',
      };
      const approvalResponse = await getProductsListWorkflowApi(payload);
      if (approvalResponse.data.length > 0) {
        this.approvalData = approvalResponse.data.map((approvalItem) => {
          let status = getStatusNameById(approvalItem.status);
          let color = getColorTextStatusById(approvalItem.status);

          if (approvalItem.status === STATUS_FIELD.STATUS_SUBMIT) {
            status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
            color = getColorTextStatusById(STATUS_FIELD.STATUS_REPRENSENT_RETURN)
          }

          if (approvalItem.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
            status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          }
          const product = approvalItem.product?.product_master;
          const branchData = product?.branch_id ? product?.branch : product;

          return {
            ...approvalItem,
            status: status,
            approver_info: approvalItem?.approved_by
              ? approvalItem?.approver?.name
              : approvalItem?.selected_approver.name,
            submitter: approvalItem?.submitter?.name,
            log_history: 'ログ表示',
            scope: getScopeName(parseInt(approvalItem.scope)),
            category: getCategoryName(parseInt(approvalItem.scope), parseInt(approvalItem.category)),
            layer1: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.method,
            layer2: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.sub_method,
            lasted_update: formatDateTimeDataTable(approvalItem.updated_at),
            month_year_registration:
              approvalItem.product?.year !== null && approvalItem.product?.month !== null
                ? `${approvalItem.product.year}/${String(approvalItem.product.month).padStart(2, '0')}`
                : '',
            iconComment: approvalItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
            scope_id: parseInt(approvalItem.scope),
            category_id: parseInt(approvalItem.category),
            date_submit: formatDateTimeDataTable(approvalItem.submitted_at),
            date_approval: formatDateTimeDataTable(approvalItem.approved_at),
            contractor_name: approvalItem.contractor?.name,
            duration_id: approvalItem.duration_id,
            approved_by: approvalItem.approved_by,
            curent_user: this.userName,
            products_name: approvalItem.product?.product_master?.name,
            product_code: approvalItem.product?.product_master?.code,
            organizational_division:
              branchData?.organizational_division !== null
                ? ORGANIZATIONAL_DATA[branchData?.organizational_division]
                : null,
            company_name: branchData?.company_name || null,
            business_name: branchData?.business_name || null,
            country: branchData?.country || null,
            layer_3: branchData?.layer_3 || null,
            layer_4: branchData?.layer_4 || null,
            layer_5: branchData?.layer_5 || null,
            layer_6: branchData?.layer_6 || null,
            registration_period:
              approvalItem.product?.year !== null && approvalItem.product?.month !== null
                ? getPeriodFromMonthYear(moment, this.startMonth, approvalItem.product.month, approvalItem.product.year)
                : '',
            colorStatus: color
          };
        });
      }
      this.listLayer = approvalResponse.layer;
      this.defineTable();
    },
    handleEvent() {
      let isHandlingCollectionChange = false;
      this.approvalGrid.collectionChanged.addHandler(
        throttle(async () => {
          if (isHandlingCollectionChange) {
            return;
          }

          isHandlingCollectionChange = true;
          setTimeout(() => {
            isHandlingCollectionChange = false;
          }, 100);

          let edited = {};
          this.approvalGrid.itemsEdited.forEach((approvalItem, approvalIndex) => {
            if (approvalItem.title) {
              // prepare data: title, id, ...
              edited[approvalIndex] = {
                id: approvalItem.id,
                title: approvalItem.title,
              };
            }
          });

          if (Object.values(edited).length) {
            try {
              this.approvalGrid.itemsEdited.length = 0;
            } catch (error) {
              this.approvalGrid.itemsEdited.length = 0;
            }
          }
        }, 300),
      );
    },
    initialView() {
      if (this.approvalGrid) {
        this.approvalData = this.approvalGrid.items.filter(
          (approvalItem) => approvalItem.id && approvalItem.id !== BLANK_ID,
        );
      }
      this.approvalGrid = new CollectionView([...this.approvalData], {
        trackChanges: true,
      });
      this.handleEvent();
      this.flexGrid.columnGroups = this.getApprovalColumns();
      this.approvalGrid.currentItem = null;
      this.flexGrid.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }
      });
      this.markUnreadRows();
      this.isEmptySource = this.approvalGrid?.items?.length <= 0;
      setMinMaxSizeColumns(this.flexGrid, this.approvalData);
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    getApprovalColumns() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      };
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly: true,
          cssClassAll: 'btn-db',
          multiLine: true,
        };
      });
      return [
          {
            header: "#",
            binding: "id",
            allowSorting: false,
            isReadOnly: true,
            visible: false
          },
          {
            header: " ",
            binding: "unique_id",
            minWidth: getWidthOfDetailButton(),
            maxWidth: getWidthOfDetailButton(),
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: "btn-db",
            cellTemplate: CellMaker.makeButton({
              text: this.$t('b_list_approval.button_detail'),
              click: (event, context) => this.onIdClicking(context)
            }),
            cssClassAll: "height34 approval-cell_custom hide-filter"
          },
          {
            header: this.$t('b_list_approval.table_status'),
            binding: "status",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_status')),
            maxWidth: 350,
            allowSorting: false,
            isRequired: false,
            isReadOnly: true,
            multiLine: true,
            wordWrap: true,
            cellTemplate: (ctx) => (`<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`),
            cssClassAll: "height34 approval-cell_status"
          },
          {
            header: " ",
            binding: "iconComment",
            minWidth: 32,
            maxWidth: 32,
            allowSorting: false,
            isRequired: false,
            cssClass: "btn-db",
            cellTemplate: CellMaker.makeImage({
              click: (event, context) => this.onCommentClicking(context)
            }),
            cssClassAll: "approval-cell_comment hide-filter comment-icon",
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_submitter'),
            binding: "submitter",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_submitter')),
            maxWidth: 220,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_approver_info'),
            binding: "approver_info",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_approver_info')),
            maxWidth: 300,
            allowSorting: false,
            isRequired: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: "approver_info",
            cellTemplate: "<span class=\"${(item.approver_info !== item.curent_user) && \"not-same-name\"}\">${item.approver_info}</span>"
          },
          {
            header: this.$t('b_list_approval.table_date_submit'),
            binding: "date_submit",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_date_submit')),
            maxWidth: 200,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_date_approval'),
            binding: "date_approval",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_date_approval')),
            maxWidth: 200,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_title'),
            binding: "title",
            minWidth: 196,
            maxWidth: 980,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: "readOnly-approved"
          },
          {
            header: this.$t('b_list_approval.table_products_name'),
            binding: "products_name",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_products_name')),
            maxWidth: 200,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_product_code'),
            binding: "product_code",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_product_code')),
            maxWidth: 200,
            allowSorting: false,
            isReadOnly: false,
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_organizational_information'),
            align: "center",
            collapseTo: 'organizational_division',
            columns: [
            {
                header: this.$t('b_list_approval.table_organizational_division'),
                binding: "organizational_division",
                minWidth: getMinWidthOrganization(),
                maxWidth: 400,
                allowSorting: false,
                isReadOnly: false,
                wordWrap: true
              },
              {
                header: this.$t('b_list_approval.table_company_name'),
                binding: "company_name",
                minWidth: getWidthByTextContent(this.$t('b_list_approval.table_company_name')) + 5,
                maxWidth: 400,
                allowSorting: false,
                isReadOnly: false,
                wordWrap: true
              },
              ...layers
            ]
          },
          {
            header: this.$t('b_list_approval.table_registration_period'),
            binding: "registration_period",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_registration_period')),
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            isReadOnly: false,
            cssClass: "btn-db",
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_month_year_registration'),
            binding: "month_year_registration",
            minWidth: getWidthByTextContent(this.$t('b_list_approval.table_month_year_registration')),
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            isReadOnly: false,
            cssClass: "btn-db",
            multiLine: true,
            wordWrap: true
          },
          {
            header: this.$t('b_list_approval.table_log_history'),
            binding: "log_history",
            minWidth: getWidthByText(this.$t('b_list_approval.table_log_history')),
            maxWidth: 500,
            allowSorting: false,
            cssClass: "btn-db",
            cssClassAll: "cell_comment-confirm hide-filter",
            multiLine: true,
            wordWrap: true,
            cellTemplate: CellMaker.makeButton({
              text: this.$t('b_list_approval.button_log_display'),
              click: (e, ctx) => this.onHistoryClicking(ctx)
            })
          }
        ];
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.$router.push({ path: `/products/register?type=approval&id=${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId,
      };
      const commentList = await getProductListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map((commentHistory) => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.updated_at),
          name: commentHistory.user.name,
          content: commentHistory.content,
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId,
      };
      const listHistoryChanged = await getProductListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map((listHistoryChanged) => {
        let status = getStatusNameById(listHistoryChanged.status);
        let colorStatus = getColorStatusById(listHistoryChanged.status);

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_SUBMIT) {
          status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
        }

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
          status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
        }

        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.created_at),
          name: listHistoryChanged.content,
          status: status,
          color: colorStatus,
        };
      });
    },
    defineTable() {
      this.approvalColumn = this.getApprovalColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
      this.canUndo = false;
      this.canRedo = false;
      const stateChanged = (event) => {
        this.canUndo = event.canUndo;
        this.canRedo = event.canRedo;
      };
      this.undoStack = new UndoStack(flexgrid, stateChanged);
    },
    markUnreadRows() {
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.approvalGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread =
            currentItem !== undefined && currentItem['unread_flg'] !== undefined ? currentItem['unread_flg'] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add('wj-has-unread-mark');
            }
          }
        }
      });
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          dataWorkflow: {
            id: parseInt(this.$route.query.workflow_data_id),
            comment: '',
          },
          is_allow_cancel: CANCEL_TYPE.ACCEPT,
        };
        await cancelSubmittedData(payload);
        this.$router.replace({ path: '/approval' });
      } catch (error) {
        this.$router.replace({ path: '/approval' });
      }
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
  },
};
</script>
  <style lang="scss">
.table-list-approval .wj-flexgrid .readOnly-approved.wj-cell[aria-readonly] {
  background: #f7f7f2 !important;
  color: #404d50 !important;
}
// .table-list-approval .wj-elem-collapse {
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   left: 8px;
//   top: 6px;
//   background: rgba(121, 134, 134, 0.12);
//   border-radius: 6px;
// }
// .table-list-approval .wj-elem-collapse .wj-glyph-minus {
//   color: #7c898c;
//   border-top: 1px solid;
// }
.setting-page {
  .wj-flexgrid {
    .wj-cells {
      .wj-state-active:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
    }
  }
}
</style>
  <style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;
        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      @media (max-width: 768px) {
        padding-bottom: 58px;
      }

      &__content {
        flex-direction: row;
      }

      span,
      .wrap-title {
        display: inline;
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }
  }

  // tooltip icon
  .helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    img {
      z-index: 1;
    }
    &.tooltip {
      display: block;
      justify-content: left;
      background: $monoWhite;
      border-radius: 4px;
      color: $monoBlack;
      margin: 0 auto;
      font-size: 15px;
      padding: 10px;
      height: unset;
      width: 315px;
      max-width: max-content;
      position: absolute;
      right: unset;
      left: 0px;
      bottom: unset;
      z-index: 9999;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

      position: fixed;
      width: 100%;
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
  